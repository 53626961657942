import React from 'react';
import { Link } from "gatsby"
import "../../styles/super-sathi.css"
import Arrow from "../../images/super-sathi/arrow.png"
import Instagram from "../../images/super-sathi/Instagram.png"
import Facebook from "../../images/super-sathi/Facebook.png"
import Linkedin from "../../images/super-sathi/LinkedIn.png"
import instra from "../../images/super-sathi/instra.png"
import logo from '../../images/super-sathi/logo.png'
import feedLendingBanner from "../../images/super-sathi/feed-landing-banner.png"
import feed from "../../images/super-sathi/feed.png"
import lemitset from "../../images/super-sathi/lemitset.png"
import strongness from "../../images/super-sathi/strongness.png"
import overmilking from "../../images/super-sathi/over-milking.png"
import levels from "../../images/super-sathi/noun-levels.png"
import cowGroup from "../../images/super-sathi/cow-group.png"
import moofeed from "../../images/super-sathi/moofeed.png";
import check from "../../images/super-sathi/check.png";
import pic from "../../images/super-sathi/siv.png";
import map from "../../images/super-sathi/map-pin.png";
import mail from "../../images/super-sathi/mail.png";
import phone from "../../images/super-sathi/phone.png";
import { Helmet } from "react-helmet"
import Slider from "react-slick";
import $ from "jquery"

let moofeedInfo = [
    {
        heading: "MOOO SPECIAL",
        color: '#E77320',
        title: "10 ਲੀਟਰ ਦੁੱਧ ਦੇਣ ਵਾਲੇ ਪਸ਼ੂਆਂ ਲਈ",
        src: require("../../images/super-sathi/moofeedorange.png"),
        list1: "ਪਸ਼ੂਆਂ ਦੀ ਸਿਹਤ ਵਿੱਚ ਪਹਿਲਾਂ ਨਾਲੋਂ ਸੁਧਾਰ ",
        list2: "ਦੁੱਧ ਦੀ ਪੈਦਾਵਰ ਵਿੱਚ ਵਾਧਾ",
        list3: "Immunity Booster ਬਣਕੇ, ਕਈ ਬਿਮਾਰੀਆਂ ਤੋਂ ਬਚਾਅ",
        list4: "Aflatoxin level ਦੀ ਮਾਤਰਾ 'ਚ control",
    },
    {
        heading: "MOOO 5000",
        color: '#DA6981',
        title: "11-15 ਲੀਟਰ ਦੁੱਧ ਦੇਣ ਵਾਲੇ ਪਸ਼ੂਆਂ ਲਈ",
        src: require("../../images/super-sathi/moofeed.png"),
        list1: "ਪਸ਼ੂਆਂ ਦੀ ਸਿਹਤ ਵਿੱਚ ਪਹਿਲਾਂ ਨਾਲੋਂ ਸੁਧਾਰ",
        list2: "ਦੁੱਧ ਦੀ ਪੈਦਾਵਰ ਵਿੱਚ ਵਾਧਾ",
        list3: "Immunity Booster ਬਣਕੇ, ਕਈ ਬਿਮਾਰੀਆਂ ਤੋਂ ਬਚਾਅ",
        list4: "Aflatoxin level ਦੀ ਮਾਤਰਾ 'ਚ control",
    },
    {
        heading: "MOOO 8000",
        color: "#3C8FC3",
        title: "16-25 ਲੀਟਰ ਦੁੱਧ ਦੇਣ ਵਾਲੇ ਪਸ਼ੂਆਂ ਲਈ",
        src: require("../../images/super-sathi/moofeedblue.png"),
        list1: "ਪਸ਼ੂਆਂ ਦੇ ਬਾਰ-ਬਾਰ ਫਿਰਨ ਦੀ ਸਮੱਸਿਆ ਨੂੰ ਘਟਾ ਕੇ, ਪ੍ਰਜਨਨ ਪ੍ਰਣਾਲੀ ਵਿੱਚ ਸੁਧਾਰ",
        list2: "ਪਸ਼ੂਆਂ ਦੀ ਸਿਹਤ ਵਿੱਚ ਪਹਿਲਾਂ ਨਾਲੋਂ ਸੁਧਾਰ  ਅਤੇ ਦੁੱਧ ਵਿੱਚ ਵਾਧਾ",
        list3: "Immunity Booster ਬਣਕੇ, ਕਈ ਬਿਮਾਰੀਆਂ ਤੋਂ ਬਚਾਅ",
        list4: "Aflatoxin level ਦੀ ਮਾਤਰਾ 'ਚ control",
    },
    {
        heading: "MOOO 10000",
        color: "#69984F",
        title: "26 ਲੀਟਰ ਦੁੱਧ ਦੇਣ ਵਾਲੇ ਪਸ਼ੂਆਂ ਲਈ",
        src: require("../../images/super-sathi/moofeedgreen.png"),
        list1: "",
        list2: "ਦੁੱਧ ਦੀ ਗੁਣਵੱਤਾ, ਫੈਟ, SNF ਅਤੇ BCS ਵਿੱਚ ਵਾਧਾ ",
        list3: "Immunity Booster ਬਣਕੇ, ਕਈ ਬਿਮਾਰੀਆਂ ਤੋਂ ਬਚਾਅ",
        list4: "Aflatoxin level ਦੀ ਮਾਤਰਾ 'ਚ control",
    },
]

let farmerReview = [
    {
        name: "ਇੰਦਰਜੀਤ ਸਿੰਘ",
        position: 'ਰਾਜਪੁਰਾ',
        src: require('../../images/super-sathi/ਰਾਜਪੁਰਾ.png'),
        dec: "“ਜਦੋਂ ਤੋਂ ਅਸੀਂ ਆਪਣੇ ਪਸ਼ੂਆਂ ਲਈ ਮੂ ਫੀਡ ਖਰੀਦੀ ਹੈ, ਉਦੋਂ ਤੋਂ ਪਸ਼ੂ ਜ਼ਿਆਦਾ ਦੁੱਧ ਦੇ ਰਿਹਾ ਹੈ ਅਤੇ ਇਸ ਫੀਡ ਵਿੱਚ ਯੂਰੀਆ ਦੀ ਮਾਤਰਾ ਘੱਟ ਹੋਣ ਕਾਰਨ ਸਾਡੇ ਪਸ਼ੂਆਂ ਦੀ ਸਿਹਤ ਵਿੱਚ ਵੀ ਕਾਫੀ ਸੁਧਾਰ ਹੋਇਆ ਹੈ। ਮੂ ਫੀਡ ਦੀ ਮਹਿਕ ਵੀ ਇੰਨੀ ਵਧੀਆ ਹੈ ਕਿ ਸਾਡੇ ਜਾਨਵਰ ਇਸ ਨੂੰ ਖੁਸ਼ੀ ਨਾਲ ਖਾਂਦੇ ਹਨ।”",
    },
    // {
    //     name: "रामराय गुर्जर",
    //     position: 'निवाई, टोंक',
    //     src: pic,
    //     dec: "“मू फीड की क्वालिटी बहुत ही बढ़िया है! जब से मैंने मू फीड बेचना शुरू किया है, मेरे किसान भाई और बहनों के पशुओं की दूध उत्पादकता बढ़ गई है। साथ ही साथ मेरा बिजनेस भी 50% से अधिक बढ़ गया है और इसके लिए मैं मूफार्म का बहुत धन्यवाद करता हुआ। मूफार्म की मदद से मैं अपने बिजनेस को मुनाफे की ओर ले जा पाया।”",
    // },
    // {
    //     name: "रामराय गुर्जर",
    //     position: 'निवाई, टोंक',
    //     src: pic,
    //     dec: "“मू फीड की क्वालिटी बहुत ही बढ़िया है! जब से मैंने मू फीड बेचना शुरू किया है, मेरे किसान भाई और बहनों के पशुओं की दूध उत्पादकता बढ़ गई है। साथ ही साथ मेरा बिजनेस भी 50% से अधिक बढ़ गया है और इसके लिए मैं मूफार्म का बहुत धन्यवाद करता हुआ। मूफार्म की मदद से मैं अपने बिजनेस को मुनाफे की ओर ले जा पाया।”",
    // },
    // {
    //     name: "रामराय गुर्जर",
    //     position: 'निवाई, टोंक',
    //     src: pic,
    //     dec: "“मू फीड की क्वालिटी बहुत ही बढ़िया है! जब से मैंने मू फीड बेचना शुरू किया है, मेरे किसान भाई और बहनों के पशुओं की दूध उत्पादकता बढ़ गई है। साथ ही साथ मेरा बिजनेस भी 50% से अधिक बढ़ गया है और इसके लिए मैं मूफार्म का बहुत धन्यवाद करता हुआ। मूफार्म की मदद से मैं अपने बिजनेस को मुनाफे की ओर ले जा पाया।”",
    // },
]

class Landing extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerShow: false
        };
    }

    componentDidMount() {
        this._handleClick();
    }

    componentDidUpdate() {
        this._handleClick();
    }

    _handleClick() {
        $(function () {
            $(window).on("scroll", function () {
                if ($(window).scrollTop() > 200) {
                    $(".sticky-header").removeClass("d-none");
                } else {
                    $(".sticky-header").addClass("d-none");
                }
            });
        });
    }

    render() {
        const settings = {
            className: "slider variable-width slider-box",
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            autoplay: false,
            arrow: false
        }
        const settings2 = {
            className: "slider",
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            // variableWidth: true,
            autoplay: false,
            arrow: false
        }
        return (
            <div className='main-container super-sathi-container'>
                <Helmet>
                    <link
                        href="https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700&display=swap"
                        rel="stylesheet"
                    />
                    <link
                        rel="stylesheet"
                        href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        charset="UTF-8"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                    />
                    <link
                        rel="stylesheet"
                        type="text/css"
                        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                    />
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                    />
                    <script src="js/script.js"></script>
                    <script src="https://kit.fontawesome.com/dd15047d18.js" crossorigin="anonymous"></script>
                    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
                    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
                </Helmet>
                <div className="container super-sathi-banner">
                    <div className='row'>
                        <div className={"sticky-header d-none"}>
                            <img src={logo} className="moofarm-logo" />
                            <button onClick={() => { window.location.href = "/feedpun/feed-form-p" }}>ਹੁਣੇ ਖਰੀਦੋ</button>
                        </div>
                    </div>
                    <div className="moofarm-logo-container">
                        <img src={logo} className="moofarm-logo" />
                    </div>
                    <div className="banner-body">
                        <h3>ਅੱਜ ਹੀ  ਮੂ ਫੀਡ ਖਰੀਦੋ</h3>
                        <p>ਭਾਰਤ ਅਤੇ ਆਸਟ੍ਰੇਲੀਆ ਦੇ ਮਾਹਿਰ ਪੋਸ਼ਣ ਡਾਕਟਰਾਂ ਦੁਆਰਾ ਤਿਆਰ ਕੀਤੀ ਗਈ ਪਸ਼ੂ ਫੀਡ  </p>
                        <button onClick={() => { window.location.href = "/feedpun/feed-form-p" }}>ਹੁਣੇ ਖਰੀਦੋ <img src={Arrow} className="arrow-img" alt="Arrow Btn" /></button>
                    </div>
                    <div className="row">
                        <div className="superSathiBanner-container">
                            <img src={feedLendingBanner} className="superSathiBanner-img" />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="why-become">
                        <div className="why-become-header">
                            <img src={feed} className="istockphoto-img" />
                            <p>ਕਯੋਂ ਹੈ ਪਸ਼ੂਆਂ ਲਈ ਮੂ ਫੀਡ ਜ਼ਰੂਰੀ ?</p>
                        </div>
                        <div className="all-list-view">
                            <div className="why-become-list">
                                <div className="list-img">
                                    <img src={levels} />
                                </div>
                                <div className="list-contant-view">
                                    <p>ਸਿਹਤ ਵਿੱਚ ਸੁਧਾਰ </p>
                                </div>
                            </div>
                            <div className="why-become-list">
                                <div className="list-img">
                                    <img src={strongness} />
                                </div>
                                <div className="list-contant-view">
                                    <p>ਬਿਮਾਰੀਆਂ ਨਾਲ ਲੜਨ ਦੀ ਸ਼ਕਤੀ 'ਚ ਵਾਧਾ</p>
                                </div>
                            </div>
                            <div className="why-become-list">
                                <div className="list-img">
                                    <img src={overmilking} />
                                </div>
                                <div className="list-contant-view">
                                    <p>ਦੁੱਧ ਦੀ ਪੈਦਾਵਰ ਵਿੱਚ ਵਾਧਾ </p>
                                </div>
                            </div>
                            <div className="why-become-list">
                                <div className="list-img">
                                    <img src={lemitset} />
                                </div>
                                <div className="list-contant-view">
                                    <p>ਅਫਲਾਟੋਕਸਿਨ ਪੱਧਰ ਤੇ ਕੰਟਰੋਲ </p>
                                </div>
                            </div>
                        </div>
                        <div className="cowGroup-container">
                            <img src={cowGroup} />
                        </div>
                    </div>
                </div>
                <div className="container super-sathi-video">
                    <h3 className="top-heading">ਮੂ ਫੀਡ ਦੀ ਹੋਰ ਜਾਣਕਾਰੀ ਲਈ ਇਹ ਵੀਡੀਓ ਜ਼ਰੂਰ ਵੇਖੋ :</h3>
                    <iframe className="video-view" src="https://www.youtube.com/embed/JYzs41UAYdc"></iframe>
                </div>
                <div className="container moofeed-info">
                    <h3 className="top-heading">ਮੂ ਫੀਡ ਕਿਸ ਪਸ਼ੂ ਨੂੰ ਦਿਓ ?</h3>
                    <Slider {...settings}>
                        {moofeedInfo.map((item, index) => {
                            return (
                                <div>
                                    <div className='moofeed-info-card'>
                                        <div className='moofeed-img'>
                                            <img
                                                src={item.src}
                                            />
                                        </div>
                                        <div className='moofeed-info-card-body'>
                                            <h3 className="moofeed-info-heading" style={{ color: item.color }}>{item.heading}</h3>
                                            <p className='moofeed-info-title'>{item.title}</p>
                                            <div className="moofeed-info-list-group">
                                                <div className="moofeed-info-list">
                                                    <img src={check} />
                                                    <p>{item.list1}</p>
                                                </div>
                                                <div className="moofeed-info-list">
                                                    <img src={check} />
                                                    <p>{item.list2}</p>
                                                </div>
                                                <div className="moofeed-info-list">
                                                    <img src={check} />
                                                    <p>{item.list3}</p>
                                                </div>
                                                <div className="moofeed-info-list">
                                                    <img src={check} />
                                                    <p>{item.list4}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
                <div className="container super-sathi-review">
                    <h3 className="top-heading">ਡੇਅਰੀ ਕਿਸਾਨਾਂ ਤੋਹ ਸੁਣੋ ਮੂ ਫੀਡ ਖਿਲਾਉਣ ਦਾ ਅਨੁਭਵ</h3>
                    <div className="">
                        <Slider {...settings2}>
                            {farmerReview.map((item, index) => {
                                return (
                                    <div className="review-card">
                                        <div className="review-card-header">
                                            <img src={item.src} />
                                            <div className="review-card-header-left">
                                                <h3 className="reviewer-name">{item.name}</h3>
                                                <p className="reviwer-position">{item.position}</p>
                                            </div>
                                        </div>
                                        <div className="review-card-body">
                                            <p>{item.dec}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
                <div className="container super-sathi-footer">
                    <div className="row">
                        <div className="col-xs-12">
                            <button className="moofeed-btn" onClick={() => { window.location.href = "/feedpun/feed-form-p" }}>ਮੂ ਫੀਡ ਡਿਸਟ੍ਰੀਬਿਊਟਰ ਬਣੋ <img src={Arrow} className="moofeed-btn-arrow" alt="Arrow Img" /></button>
                            <div className="supersathi-icons">
                                <a href="https://www.instagram.com/mooofarm/" target="_blank">
                                    <img
                                        src={Instagram}
                                        alt="Instagram"
                                        className="supersathi-social"
                                    />
                                </a>
                                <a href="https://www.facebook.com/mooofarm" target="_blank">
                                    <img
                                        src={Facebook}
                                        alt="Facebook"
                                        className="supersathi-social"
                                    />
                                </a>
                                <a
                                    href="https://in.linkedin.com/company/mooofarm"
                                    target="_blank"
                                >
                                    <img
                                        src={Linkedin}
                                        alt="Linkedin"
                                        className="supersathi-social"
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/mooofarm/?hl=en"
                                    target="_blank"
                                >
                                    <img
                                        src={instra}
                                        alt="instagram"
                                        className="supersathi-social"
                                    />
                                </a>
                            </div>
                            <div className="super-sathi-footer-info">
                                <img src={map} />
                                <p>मूफार्म,
                                    वीवर्क, गैलेक्सी  होटल , सेक्टर 15,
                                    गुरुग्राम, हरयाणा  122001   </p>
                            </div>
                            <div className="super-sathi-footer-info">
                                <img src={mail} />
                                <p>contact@mooo.farm </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className='phone-container'>
                        <img src={phone} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Landing
